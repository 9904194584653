import React, { useEffect, useRef, useState } from "react";
import {
  InputAdornment,
  TextField,
  Button,
  Grid,
  MenuItem,
  Divider,
  Container,
} from "@mui/material";
import Style from "./styles/LiveTable.module.css";
import { MobileDatePicker, MobileTimePicker } from "@mui/x-date-pickers";
import { Search } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import OptionPill from "../../components/option-pill/OptionPill";
import dayjs from "dayjs";
import { GET, POST, POST_MULTIPART } from "../../services/axiosRequestHandler";
import {
  CELL_TYPE,
  ENDPOINT,
  TOAST_CONFIG,
  USER_TYPE,
} from "../../config/constants";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../hooks/useAuth";
import {
  setRestaurantsList,
  setSelectedContacts,
} from "../../redux/slices/masterDataSlice";
import { toast } from "react-toastify";
import { useSingleAndDoubleClick } from "../../hooks/useSingleAndDoubleClick";
import BookingDetailPopup from "../../components/live-table/BookingDetailPopup";
import ChangeTablePopup from "../../components/live-table/ChangeTablePopup";
import { original } from "immer";
import NoDataFound from "../../components/no-data-found/NoDataFound";
import { async } from "q";
import { Box } from "@mui/system";
import TimeSelector from "../../components/time-selector/TimeSelector";
import {
  addMinutesToTime,
  convertToAMPM,
  formatAMPM,
  formatISODateString,
  formatReservationDate,
} from "../../utils";

import TBL1Active from "../../assets/icons/1-table-active.svg";
import TBL1Inactive from "../../assets/icons/1-table-inactive.svg";
import TBL1Arrived from "../../assets/icons/1-table-arrived.svg";
import TBL2Active from "../../assets/icons/2-table-active.svg";
import TBL2Inactive from "../../assets/icons/2-table-inactive.svg";
import TBL2Arrived from "../../assets/icons/2-table-arrived.svg";
import TBL4Active from "../../assets/icons/4-table-active.svg";
import TBL4Inactive from "../../assets/icons/4-table-inactive.svg";
import TBL4Arrived from "../../assets/icons/4-table-arrived.svg";
import TBL6Active from "../../assets/icons/6-table-active.svg";
import TBL6Inactive from "../../assets/icons/6-table-inactive.svg";
import TBL6Arrived from "../../assets/icons/6-table-arrived.svg";
import PDR6Active from "../../assets/icons/6-pdr-active.svg";
import PDR6Inactive from "../../assets/icons/6-pdr-inactive.svg";
import PDR6Arrived from "../../assets/icons/6-pdr-arrived.svg";
import PDR8Active from "../../assets/icons/8-pdr-active.svg";
import PDR8Inactive from "../../assets/icons/8-pdr-inactive.svg";
import PDR8Arrived from "../../assets/icons/8-pdr-arrived.svg";
import PDR10Active from "../../assets/icons/10-pdr-active.svg";
import PDR10Inactive from "../../assets/icons/10-pdr-inactive.svg";
import PDR10Arrived from "../../assets/icons/10-pdr-arrived.svg";
import PDR12Active from "../../assets/icons/12-pdr-active.svg";
import PDR12Inactive from "../../assets/icons/12-pdr-inactive.svg";
import PDR12Arrived from "../../assets/icons/12-pdr-arrived.svg";
import RL from "@mui/icons-material/West";
import LR from "@mui/icons-material/East";
import TB from "@mui/icons-material/South";
import BT from "@mui/icons-material/North";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Controls from "../../components/controls/Controls";

const SlotProps = {
  textField: {
    variant: "standard",
    sx: {
      width: "120px",
      padding: "8px 16px",
      marginRight: "30px",
      backgroundColor: "#F3F3F3",
      borderRadius: "8px",
      "& .MuiInput-root": {
        borderBottom: "none",
      },
      "& .MuiInput-root:before": {
        borderBottom: "none",
      },
      "& .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before": {
        borderBottom: "none",
      },
      "& .MuiInput-root:after": {
        borderBottom: "none",
      },
    },
    inputProps: {
      fontSize: "16px",
      fontWeight: "400",
      marginBottom: "5px",
    },
  },
  actionBar: {
    actions: ["clear", "accept"],
  },
};

const renderInput = (props) => (
  <TextField
    {...props}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <KeyboardArrowDownIcon />
        </InputAdornment>
      ),
    }}
  />
);

const slotProps = {
  textField: {
    placeholder: "Date",
    variant: "standard",
    sx: {
      width: "100%",
      "& .MuiInput-underline:before": {
        borderBottom: "2px solid #D9D9D9",
      },
      // marginRight: "30px",
      marginBottom: "20px",
    },
    inputProps: {
      fontSize: "16px",
      fontWeight: "400",
      marginBottom: "5px",
    },
  },
  actionBar: {
    actions: ["accept"],
  },
};

function LiveTable() {
  const dispatch = useDispatch();
  const { logout, role } = useAuth();
  const { restaurantsList } = useSelector((state) => state.masterData);
  const [searchValue, setSearchValue] = useState("");
  const [currentTime, setCurrentTime] = useState({
    hh: formatAMPM(new Date())?.hours,
    mm: formatAMPM(new Date())?.minutes,
    meridiem: formatAMPM(new Date())?.ampm,
  });
  const [restId, setRestId] = useState(null);
  const [restaurantData, setRestaurantData] = useState(null);
  const [restSpending, setRestSpending] = useState([]);
  const [layout, setLayout] = useState(null);
  const [bookedTbl, setBookedTbl] = useState(null);
  const [showBookingDetailsPopup, setShowBookingDetailsPopup] = useState(false);
  const [showChangeTablePopup, setShowChangeTablePopup] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [searchData, setSearchData] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [minuteSteps, setMinuteSteps] = useState("30");
  const [showResultPanel, setShowResultPanel] = useState(false);
  const [date, setDate] = useState(dayjs(new Date()));
  const [dateError, setDateError] = useState("");
  const [zoomState, setZoomState] = useState(null);
  const [themeDetails, setThemeDetails] = useState(null);

  const clickRef = useRef(null);
  const handleReset = () => {
    setCurrentTime({
      hh: formatAMPM(new Date())?.hours,
      mm: formatAMPM(new Date())?.minutes,
      meridiem: formatAMPM(new Date())?.ampm,
    });
    setDate(dayjs(new Date()));
    setDateError("");
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
  }, [showResultPanel]);

  const handleClickOutside = (e) => {
    if (!clickRef?.current?.contains(e.target)) {
      // setShowResultPanel(false);
    }
  };

  useEffect(() => {
    setDate(dayjs(bookedTbl?.booking_date));
    setCurrentTime(bookedTbl ? {
      hh: bookedTbl && convertToAMPM(bookedTbl?.booking_time, true)[0],
      mm: bookedTbl && convertToAMPM(bookedTbl?.booking_time, true)[1],
      meridiem: bookedTbl && convertToAMPM(bookedTbl?.booking_time, true)[2],
    } : {
      hh: formatAMPM(new Date())?.hours,
      mm: formatAMPM(new Date())?.minutes,
      meridiem: formatAMPM(new Date())?.ampm
    } );
    
  }, [bookedTbl]);

  useEffect(() => {
    getAllRestaurants();
  }, [refresh, currentTime, restId, date]);

  useEffect(() => {
    if (restaurantsList?.data?.data?.original?.length) {
      const rest = restaurantsList?.data?.data?.original[0];
      setRestId((prev) => (prev ? prev : rest?.id));
      setRestaurantData((prev) => (prev ? prev : rest));
      setThemeDetails(restaurantsList?.data?.theme_details);
      setRestSpending((prev) =>
        prev
          ? prev
          : [
              rest?.spending_1,
              rest?.spending_2,
              rest?.spending_3,
              rest?.spending_4,
            ]
      );

      let prevRestIndex = null;
      if (restId)
        prevRestIndex = restaurantsList?.data?.data?.original?.findIndex(
          (item) => item?.id === restId
        );

      if (prevRestIndex && prevRestIndex >= 0)
        setLayout(
          JSON.parse(
            restaurantsList?.data?.data?.original[prevRestIndex]?.restroLayout
          )
        );
      else
        setLayout(
          JSON.parse(restaurantsList?.data?.data?.original[0]?.restroLayout)
        );
    }
  }, [restaurantsList]);

  useEffect(() => {
    setSearchLoading(true);
    const searchData = setTimeout(() => {
      searchValue ? handleSearch() : setSearchData([]);
    }, 1000);
    return () => {
      clearTimeout(searchData);
      setSearchLoading(false);
    };
  }, [searchValue]);

  useEffect(() => {
    dispatch(setSelectedContacts([]));
  }, []);

  const handleSearch = async () => {
    try {
      setSearchLoading(true);
      const payload = new FormData();
      payload.append("search", searchValue);
      payload.append(
        "reservationDate",
        formatISODateString(new Date(date).toISOString())
      );
      const resp = await POST_MULTIPART(
        role === USER_TYPE.MANAGER
          ? ENDPOINT.GET_LIVE_RESERVATION_SEARCH
          : ENDPOINT.GET_LIVE_RESERVATION_SEARCH,
        payload
      );
      if (resp?.response?.data?.status === 200) {
        setSearchData(resp.response.data.searchData);
        // dispatch(setWhosHereList(resp?.response?.data));
        // setTotalPageCount(
        //   resp?.response?.data?.totalRecordsCount > PAGE_SIZE
        //     ? Math.ceil(resp?.response?.data?.totalRecordsCount / PAGE_SIZE)
        //     : 0
        // );
        // setSearchData(resp?.response?.data?.data);
      } else if (
        resp?.response?.data?.status === 401 &&
        resp?.response?.data?.message === "User Token Expired"
      ) {
        toast.error("Your session has expired!", TOAST_CONFIG);
        logout();
      } else {
        toast.error(resp?.response?.data?.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error(error?.message, TOAST_CONFIG);
    } finally {
      setSearchLoading(false);
    }
  };

  useEffect(() => {
    if (!searchLoading && searchValue && searchData?.length > 0) {
      setShowResultPanel(true);
    } else setShowResultPanel(false);
  }, [searchLoading, searchValue, searchData]);

  const handleSingleClick = () => {
    setShowBookingDetailsPopup(true);
  };

  const handleDoubleClick = () => {
    setShowChangeTablePopup(true);
  };

  const onTableClick = useSingleAndDoubleClick(
    handleSingleClick,
    handleDoubleClick,
    250
  );

  const handleTableClick = (e) => {
    if (!e.booked) {
      return;
    }
    handleMenuItemeClick(e);
    // setBookedTbl(e);
    // onTableClick();
  };
  const handleMenuItemeClick = (e) => {
    setBookedTbl(e);

    // setShowResultPanel(false);
    if (e?.restId) {
      setRestId(e?.restId);
      const rest = restaurantsList?.data?.data?.original?.find(
        (item) => item?.id === e?.restId
      );
      rest && setRestaurantData(rest);
      
    }
    const restIdx = restaurantsList?.data?.data?.original?.findIndex(
      (r) => r.id === e.restId
    );
    if (restIdx >= 0) {
      const spendingArr = [];
      for (let index = 1; index <= 4; index++) {
        spendingArr.push(
          restaurantsList?.data?.data?.original[restIdx][`spending_${index}`]
        );
      }
      setRestSpending(spendingArr);
    }
    onTableClick(e);
  };
  const getAllRestaurants = async () => {
    try {
      const payload = new FormData();
      payload.append(
        "fromTime",
        `${currentTime.hh}:${currentTime.mm}:00 ${currentTime.meridiem}`
      );
      payload.append(
        "toTime",
        addMinutesToTime(
          `${currentTime.hh}:${currentTime.mm}:00 ${currentTime.meridiem}`,
          restaurantData?.occupancy_time
            ? parseInt(restaurantData?.occupancy_time)
            : 120
        )
      );
      payload.append(
        "bookingDate",
        formatISODateString(new Date(date).toISOString())
      );

      const resp = await POST_MULTIPART(
        ENDPOINT.GET_ALL_RESTAURANTS_LIVE_TABLES,
        // ENDPOINT.GET_ALL_RESERVATION_BY_DATE_TIME,
        payload
      );
      if (resp?.response?.data?.status === 200) {
        dispatch(setRestaurantsList(resp?.response?.data));
      } else if (
        resp?.response?.data?.status === 401 &&
        resp?.response?.data?.message === "User Token Expired"
      ) {
        toast.error("Your session has expired!", TOAST_CONFIG);
        logout();
      } else {
        toast.error(resp?.response?.data?.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error(error?.message, TOAST_CONFIG);
    }
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleTimeChange = (e) => {
    // setCurrentTime(newTime);
    setCurrentTime((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleRestaurantOptionPillClick = (
    newRestaurantOption,
    isChangeTable
  ) => {
    setRestId(newRestaurantOption.id);
    setRestaurantData(newRestaurantOption);
    setMinuteSteps(newRestaurantOption.time_interval);
    setRestSpending([
      newRestaurantOption?.spending_1,
      newRestaurantOption?.spending_2,
      newRestaurantOption?.spending_3,
      newRestaurantOption?.spending_4,
    ]);
    setLayout(JSON.parse(newRestaurantOption?.restroLayout));
    !isChangeTable && setBookedTbl(null);
  };

  const getTableShape = (table, isActive) => {
    const { isArrived } = table;
    switch (parseInt(table?.capacity)) {
      case 1:
        return (
          <img
            className={Style.tblImage}
            src={
              isActive && isArrived
                ? TBL1Arrived
                : isActive
                ? TBL1Active
                : TBL1Inactive
            }
          />
        );
      case 2:
        return (
          <img
            className={Style.tblImage}
            src={
              isActive && isArrived
                ? TBL2Arrived
                : isActive
                ? TBL2Active
                : TBL2Inactive
            }
          />
        );
      case 4:
        return (
          <img
            className={Style.tblImage}
            src={
              isActive && isArrived
                ? TBL4Arrived
                : isActive
                ? TBL4Active
                : TBL4Inactive
            }
          />
        );
      case 6:
        return (
          <img
            className={Style.tblImage}
            src={
              isActive && isArrived
                ? TBL6Arrived
                : isActive
                ? TBL6Active
                : TBL6Inactive
            }
          />
        );
      default:
        return (
          <img
            className={Style.pdrImage}
            src={
              isActive && isArrived
                ? TBL4Arrived
                : isActive
                ? TBL4Active
                : TBL4Inactive
            }
          />
        );
    }
  };

  const getPDRShape = (pdr, isActive) => {
    const { isArrived } = pdr;
    switch (parseInt(pdr?.capacity)) {
      case 6:
        return (
          <img
            className={Style.pdrImage}
            src={
              isActive && isArrived
                ? PDR6Arrived
                : isActive
                ? PDR6Active
                : PDR6Inactive
            }
          />
        );
      case 8:
        return (
          <img
            className={Style.pdrImage}
            src={
              isActive && isArrived
                ? PDR8Arrived
                : isActive
                ? PDR8Active
                : PDR8Inactive
            }
          />
        );
      case 10:
        return (
          <img
            className={Style.pdrImage}
            src={
              isActive && isArrived
                ? PDR10Arrived
                : isActive
                ? PDR10Active
                : PDR10Inactive
            }
          />
        );
      case 12:
        return (
          <img
            className={Style.pdrImage}
            src={
              isActive && isArrived
                ? PDR12Arrived
                : isActive
                ? PDR12Active
                : PDR12Inactive
            }
          />
        );
      default:
        return (
          <img
            className={Style.pdrImage}
            src={
              isActive && isArrived
                ? PDR6Arrived
                : isActive
                ? PDR6Active
                : PDR6Inactive
            }
          />
        );
    }
  };

  const getEntranceDirection = (cell) => {
    switch (cell?.direction) {
      case "top-down":
        return <TB />;
      case "down-top":
        return <BT />;
      case "left-right":
        return <LR />;
      case "right-left":
        return <RL />;
      default:
        return null;
    }
  };

  const getCell = (row, col) => {
    const cellData = layout?.data?.filter(
      (cell) => cell.y === col && cell.x === row
    );

    if (cellData.length) {
      const cell = cellData[0];
      if (cell.type === CELL_TYPE.TABLE)
        return (
          <div
            className={Style.tableCell}
            onClick={() => handleTableClick(cell)}
          >
            {getTableShape(
              cell,
              (bookedTbl?.x === cell?.x && bookedTbl?.y === cell?.y) ||
                cell?.booked
            )}
            <span className={Style.tblNumber}>{cell.number}</span>
          </div>
        );
      if (cell.type === CELL_TYPE.PDR)
        return (
          <div
            className={Style.tableCell}
            onClick={() => handleTableClick(cell)}
          >
            {getPDRShape(
              cell,
              (bookedTbl?.x === cell?.x && bookedTbl?.y === cell?.y) ||
                cell?.booked
            )}
            <span className={Style.tblNumber}>{cell.number}</span>
          </div>
        );
      if (cell.type === CELL_TYPE.BAR)
        return (
          <div className={Style.cellInside}>
            <div id="bar" className={Style.barCell}></div>
          </div>
        );
      if (cell.type === CELL_TYPE.ENTRANCE)
        return (
          <div id="rntrance" className={Style.redCarpet}>
            {getEntranceDirection(cell)}
          </div>
        );
      return cell.type;
    } else return <div className={Style.emptyCell}></div>;
  };

  function renderGrid() {
    const grid = [];

    for (let i = 0; i < layout?.row; i++) {
      const row = [];
      for (let j = 0; j < layout?.col; j++) {
        // You can customize the content of each grid cell here
        row.push(
          <div className={Style.gridCell} key={`${i}-${j}`}>
            {/* {`${j}-${i}`} */}
            {getCell(j, i)}
          </div>
        );
      }
      grid.push(
        <div className={Style.gridRow} key={i}>
          {row}
        </div>
      );
    }

    return grid;
  }

  const handleCloseBookingPopup = () => {
    setShowBookingDetailsPopup(false);
    setBookedTbl(null);
    setRefresh((prev) => prev + 1);
  };

  const handleCloseChangeTablePopup = () => {
    handleCloseBookingPopup();
    setShowChangeTablePopup(false);
    setBookedTbl(null);
    setRefresh((prev) => prev + 1);
    handleReset();
  };

  const getRestName = (id) => {
    let restName = "";

    const restIdx = restaurantsList?.data?.data?.original?.findIndex(
      (r) => r.id === id
    );
    if (restIdx >= 0) {
      restName = restaurantsList?.data?.data?.original[restIdx]?.name;
    }
    return restName;
  };

  if (restaurantsList?.data?.data?.original?.length === 0) {
    return <NoDataFound />;
  }

  const handleDateChange = (date) => {
    setDate(date);
    setDateError("");
  };

  return (
    <>
      <div className={Style.container}>
        <div className={Style.searchWrapper}>
          <TextField
            id="searchName"
            name="searchName"
            fullWidth
            autoComplete="off"
            type="text"
            placeholder="Search"
            value={searchValue}
            onChange={handleSearchChange}
            sx={{ mt: 3, mb: "20px" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            inputProps={{
              className: Style.searchInput,
            }}
          />
          {showResultPanel && (
            <Grid
              container
              position="absolute"
              sx={{ zIndex: 5 }}
              ref={clickRef}
            >
              <Grid item xs={12} lg={12}>
                <div className={Style.searchResultWrapper}>
                  {searchData?.map((item) => (
                    <div key={item?.id}>
                      <MenuItem
                        sx={{
                          borderRadius: 1,
                        }}
                        onClick={() => handleMenuItemeClick(item)}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <p>
                            {item?.name || ""} {item?.lastName || ""}
                          </p>
                          <Divider
                            orientation="vertical"
                            flexItem
                            sx={{ mx: 1 }}
                          />
                          <p>{getRestName(item?.restId)}</p>
                          <Divider
                            orientation="vertical"
                            flexItem
                            sx={{ mx: 1 }}
                          />
                          <p>{formatReservationDate(item?.booking_date)}</p>
                          <Divider
                            orientation="vertical"
                            flexItem
                            sx={{ mx: 1 }}
                          />
                          <p>{convertToAMPM(item?.booking_time)}</p>
                          <Divider
                            orientation="vertical"
                            flexItem
                            sx={{ mx: 1 }}
                          />
                          <p>PAX: {item?.peopleCount}</p>
                          <Divider
                            orientation="vertical"
                            flexItem
                            sx={{ mx: 1 }}
                          />
                          <p>Table No. {item?.tableNumber}</p>
                        </Box>
                      </MenuItem>
                      <Divider orientation="horizontal" sx={{ my: 2 }} />
                    </div>
                  ))}
                </div>
              </Grid>
            </Grid>
          )}
          {!searchLoading && searchValue && searchData?.length === 0 && (
            <Grid container position="absolute" sx={{ zIndex: 1 }}>
              <Grid item xs={12} lg={12}>
                <div className={Style.searchResultWrapper}>
                  <p>No result found !</p>
                </div>
              </Grid>
            </Grid>
          )}
        </div>
        <div className={Style.filterWrapper}>
          <div className={Style.filterContainer}>
            <div className={Style.emptyLine}></div>
            <div className={Style.restuarantWrapper}>
              <p
                style={{
                  fontWeight: "600",
                  marginBottom: "15px",
                  fontSize: "16px",
                }}
              >
                Restaurants:
              </p>
              <div>
                {restaurantsList?.data?.data?.original?.map((restaurant) => (
                  <OptionPill
                    key={restaurant.id}
                    active={restId === restaurant.id}
                    text={restaurant.name}
                    onClick={() => handleRestaurantOptionPillClick(restaurant)}
                  />
                ))}
              </div>
            </div>
            <div className={Style.emptyLine}></div>
            <div className={Style.dateTimeWrapper}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div className={Style.timeWrapper}>
                  <p
                    style={{
                      fontWeight: "600",
                      fontSize: "14px",
                    }}
                  >
                    Date
                  </p>
                  <MobileDatePicker
                    format="DD/MM/YYYY"
                    views={["year", "month", "day"]}
                    value={date}
                    slotProps={slotProps}
                    error={dateError}
                    helperText={dateError}
                    onChange={(newValue) => handleDateChange(newValue)}
                    slots={{
                      textField: (props) => (
                        <TextField
                          {...props}
                          error={dateError}
                          helperText={dateError}
                        />
                      ),
                    }}
                  />
                </div>
              </LocalizationProvider>
              <div className={Style.timeWrapper}>
                <p
                  style={{
                    fontWeight: "600",
                    fontSize: "14px",
                  }}
                >
                  Time
                </p>

                <TimeSelector
                  values={currentTime}
                  onChange={handleTimeChange}
                  removeDefault
                />
              </div>
              <Button sx={{ p: 0 }} size="small" onClick={handleReset}>
                Reset
              </Button>
            </div>
            <div className={Style.emptyLine}></div>
          </div>
        </div>
        <div className={Style.layoutContainer}>
          <div className={Style.layoutWrap}>
            <Box
              sx={{
                position: "relative",
                mx: 1,
              }}
            >
              <TransformWrapper
                initialScale={0.8}
                minScale={0.2}
                doubleClick={{
                  disabled: true,
                }}
                centerZoomedOut
                centerOnInit
                onZoom={(e) => setZoomState(e?.state)}
              >
                <Controls />
                <TransformComponent
                  wrapperStyle={{
                    width: "100%",
                    height: "60%",
                    border: "2px solid #e3e3e3",
                    borderRadius: "5px",
                  }}
                >
                  <div id="content" className={Style.layoutWrapper}>
                    {renderGrid()}
                  </div>
                </TransformComponent>
              </TransformWrapper>
            </Box>
          </div>
        </div>
      </div>

      {showBookingDetailsPopup && (
        <BookingDetailPopup
          bookingDetails={bookedTbl}
          setBookedTbl={setBookedTbl}
          open={showBookingDetailsPopup}
          restId={restId}
          restaurant={restaurantData}
          handleClose={handleCloseBookingPopup}
          spendings={restSpending}
          handleChangeTablePopup={setShowChangeTablePopup}
          themeDetails={themeDetails}
        />
      )}
      {showChangeTablePopup && (
        <ChangeTablePopup
          bookingDetails={bookedTbl}
          layout={layout}
          open={showChangeTablePopup}
          restId={restId}
          handleClose={handleCloseChangeTablePopup}
          restaurantsList={restaurantsList}
          zoomState={zoomState}
          handleRestaurantOptionPillClick={handleRestaurantOptionPillClick}
          slotProps={slotProps}
          date={date}
          time={currentTime}
          dateTimeSelector={
            <>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div className={Style.timeWrapper}>
                  <p
                    style={{
                      fontWeight: "600",
                      fontSize: "14px",
                    }}
                  >
                    Date
                  </p>
                  <MobileDatePicker
                    format="DD/MM/YYYY"
                    views={["year", "month", "day"]}
                    value={date}
                    // value={dayjs(bookedTbl?.booking_date)}
                    slotProps={slotProps}
                    error={dateError}
                    helperText={dateError}
                    onChange={(newValue) => handleDateChange(newValue)}
                    slots={{
                      textField: (props) => (
                        <TextField
                          {...props}
                          error={dateError}
                          helperText={dateError}
                        />
                      ),
                    }}
                  />
                </div>
              </LocalizationProvider>
              <div className={Style.timeWrapper}>
                <p
                  style={{
                    fontWeight: "600",
                    fontSize: "14px",
                  }}
                >
                  Time
                </p>

                <TimeSelector
                  values={currentTime}
                  onChange={handleTimeChange}
                  removeDefault
                />
              </div>
              <Button sx={{ p: 0 }} size="small" onClick={handleReset}>
                Reset
              </Button>
            </>
          }
          themeDetails={themeDetails}
        />
      )}
    </>
  );
}

export default LiveTable;
