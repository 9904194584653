import React, { useState } from "react";
import {
  Avatar,
  FormLabel,
  Checkbox,
  IconButton,
  Grid,
  Chip,
  Box,
} from "@mui/material";
import Phone from "../../assets/images/phone.svg";
import Message from "../../assets/images/message.svg";
import filterBtn from "../../assets/images/filterButtons.png";
import Add from "../../assets/images/add.svg";
import Calender from "../../assets/images/calender.svg";
import people from "../../assets/images/people.svg";
import table from "../../assets/images/dining-table.svg";
import calendarR from "../../assets/images/calendarRArw.svg";
import calendarD from "../../assets/images/calendar.svg";
import calendarL from "../../assets/images/calendarLArw.svg";
import rupee from "../../assets/images/rupee.svg";
import Glass from "../../assets/images/glass.svg";
import Style from "./styles/CustomerList.module.css";
import { toast } from "react-toastify";
import {
  convertNumbers,
  convertSimpleDateFormat,
  convertToAMPM,
  formatAMPM,
  formatDatetime,
  formatTableNumber,
  maskNumber,
} from "../../utils";
import { IMAGE_BASE_URL } from "../../config/config";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setPreviousScreen,
  setSelectedContacts,
  setSelectedIds,
  setSelectedReservations,
} from "../../redux/slices/masterDataSlice";
import { useAuth } from "../../hooks/useAuth";
import { ENDPOINT, TOAST_CONFIG, USER_TYPE } from "../../config/constants";
import { Encrypt } from "../../Crypto-Helper";
import { Label } from "@mui/icons-material";
import { POST, POST_MULTIPART } from "../../services/axiosRequestHandler";

function CustomerList({
  data,
  dashboardActions,
  component,
  showCheckBox,
  birthday,
  anniversary,
  filtered,
  reservationFilter,
  reservation,
  checkInOut,
  reservationExport,
}) {
  const { role, logout } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    selectedContacts,
    selectedIds,
    selectedReservationIds,
    restaurantsList,
  } = useSelector((state) => state.masterData);
  const [isArrived, setIsArrived] = useState(data?.isArrived || false);

  const onChecked = (e, data) => {
    if (e.target.checked) {
      dispatch(setSelectedContacts([...selectedContacts, data.contactNo]));
      dispatch(setSelectedIds([...selectedIds, data.id]));
    } else {
      if (selectedContacts.indexOf(data.contactNo) >= 0) {
        const newList = selectedContacts.filter((no) => no !== data.contactNo);
        dispatch(setSelectedContacts(newList));
      }
      if (selectedIds.indexOf(data.id) >= 0) {
        const newList = selectedContacts.filter((_id) => _id !== data.id);
        dispatch(setSelectedIds(newList));
      }
    }
  };
  const onCheckedReservation = (e, id) => {
    if (e.target.checked) {
      dispatch(setSelectedReservations([...selectedReservationIds, id]));
    } else {
      if (selectedContacts.indexOf(data.contactNo) >= 0) {
        const newList = selectedReservationIds.filter((dt) => dt !== id);
        dispatch(setSelectedReservations(newList));
      }
      if (selectedReservationIds.indexOf(id) >= 0) {
        const newList = selectedReservationIds.filter((_id) => _id !== id);
        dispatch(setSelectedReservations(newList));
      }
    }
  };

  const checkIsSelected = (id) => {
    let result = false;
    if (reservationExport) {
      if (selectedReservationIds?.includes(id)) result = true;
    } else {
      if (selectedContacts?.includes(id)) result = true;
    }
    return result;
  };

  const handleSendMessage = (id) => {
    dispatch(setSelectedContacts([id]));
    navigate("/send-template");
  };

  const getRestaurentName = (restId) => {
    let name = "";
    let rest = restaurantsList?.data?.data?.original?.filter(
      (r) => r.id === restId
    );
    if (rest && rest?.length) {
      name = rest[0].name;
    }
    return name;
  };

  const handleIsArrived = async (e, data) => {
    setIsArrived(e.target.checked);
    const formData = new FormData();
    formData.append("reservationId", data?.reservationId);
    formData.append("arrived", e.target.checked ? 1 : 0);
    try {
      const response = await POST_MULTIPART(ENDPOINT.IS_ARRIVED, formData);
      if (response?.response?.data?.status === 200) {
        toast.success(response?.response?.data?.message, TOAST_CONFIG);
      } else if (
        response?.response?.data?.status === 401 &&
        response?.response?.data?.message === "User Token Expired"
      ) {
        logout();
      } else {
        toast.error(response?.response?.data?.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error(error?.message, TOAST_CONFIG);
    }
  };
  return (
    <div className={Style.mainWrapper}>
      <div className={Style.CustomerListWrapper}>
        {role === USER_TYPE.MANAGER && showCheckBox && (
          <Checkbox
            checked={checkIsSelected(
              reservationExport ? data?.reservationId : data?.contactNo
            )}
            onChange={(e) => {
              if (reservationExport)
                onCheckedReservation(e, data?.reservationId);
              else onChecked(e, { id: data?.id, contactNo: data?.contactNo });
            }}
            className={Style.checkbox}
            sx={{ pr: 1 }}
          />
        )}
        <Link
          className={Style.CustomerInfoWrapper}
          to={
            reservationFilter
              ? `/history/${Encrypt(data.id)}`
              : // : reservation
                // ? `/reservation/${Encrypt(data.id)}`
                `/customer/edit/${Encrypt(data.id)}`
          }
        >
          <Avatar
            variant="square"
            src={
              data?.profileImage ? `${IMAGE_BASE_URL}${data?.profileImage}` : ""
            }
            className={Style.avatar}
            sx={{ borderRadius: "9px", width: "56.25px", height: "56.25px" }}
          />
          <div className={Style.customerDetail}>
            <FormLabel
              sx={{ cursor: "pointer" }}
              className={Style.customerNameLabel}
            >
              {data.name} {data.lastName}
            </FormLabel>
            {role === USER_TYPE.MANAGER ? (
              <p className={Style.phoneText}>{data?.contactNo}</p>
            ) : (
              <p className={Style.phoneText}>{maskNumber(data?.contactNo)}</p>
            )}
            {birthday ? (
              <p className={Style.dateText}>
                {data?.dob ? formatDatetime(data?.dob) : ""}
              </p>
            ) : anniversary ? (
              <p className={Style.dateText}>
                {data?.anniversaryDate
                  ? formatDatetime(data?.anniversaryDate)
                  : ""}
              </p>
            ) : (
              !checkInOut &&
              !reservation &&
              data?.created_at && (
                <p className={Style.dateText}>
                  {formatDatetime(data?.created_at)}
                </p>
              )
            )}
            {data?.category?.length > 0 && component !== "whosHere" ? (
              <>
                <div className={Style.initialWrapper}>
                  {data?.category?.map((cat, innerIndex) => (
                    <p className={Style.textField} key={innerIndex}>
                      {cat.alphabets}
                    </p>
                  ))}
                  {data?.isDnd ? (
                    <p className={`${Style.textField} ${Style.dnd} `}>X</p>
                  ) : null}
                </div>
              </>
            ) : null}
            {/* reservation */}
            {reservation && (
              <div className={Style.labelWraper}>
                {data?.booking_time ? (
                  <div className={Style.label}>
                    <img
                      className={Style.img}
                      src={calendarR}
                      alt="calendar_icon"
                    />
                    <p>{convertToAMPM(data?.booking_time)}</p>
                  </div>
                ) : null}
                {data?.booking_date && reservationExport ? (
                  <div className={Style.label}>
                    <img
                      className={Style.img}
                      src={calendarD}
                      alt="calendar_icon"
                    />
                    <p>{convertSimpleDateFormat(data?.booking_date)}</p>
                  </div>
                ) : null}
                <div className={Style.label}>
                  <img className={Style.img} src={people} alt="people_icon" />
                  <p>
                    {data?.peopleCount?.toString().length === 1
                      ? `0${data?.peopleCount}`
                      : data?.peopleCount}
                  </p>
                </div>
                {(data?.tableNumberDetails || data?.pdrNumberDetails) && (
                  <div className={Style.label}>
                    <img
                      style={{ height: "20px" }}
                      className={Style.img}
                      src={table}
                      alt="table_icon"
                    />
                    <p>
                      {formatTableNumber(
                        data?.tableNumberDetails
                          ? data.tableNumberDetails +
                              (data?.pdrNumberDetails
                                ? `, ${data.pdrNumberDetails}`
                                : "")
                          : data?.pdrNumberDetails
                      )}
                    </p>
                  </div>
                )}
                {data?.spending && (
                  <div className={Style.label}>
                    <img className={Style.img} src={rupee} alt="rupee_icon" />
                    {data?.spending}
                  </div>
                )}
              </div>
            )}

            {/* Check In / Out */}
            {checkInOut && (
              <div className={Style.labelWraper}>
                {data?.check_in && (
                  <div className={Style.label}>
                    <img className={Style.img} src={calendarL} alt="message" />
                    {convertSimpleDateFormat(data?.check_in)}
                  </div>
                )}
                {data?.check_out && (
                  <div className={Style.label}>
                    <img className={Style.img} src={calendarR} alt="message" />
                    {convertSimpleDateFormat(data?.check_out)}
                  </div>
                )}
              </div>
            )}
            <div className={Style.labelWraper}>
              {reservation ? (
                data?.restaurantName ? (
                  <div className={Style.label}>
                    <p>{data?.restaurantName}</p>
                  </div>
                ) : data?.restId ? (
                  <div className={Style.label}>
                    <p>{getRestaurentName(data?.restId)}</p>
                  </div>
                ) : null
              ) : null}
              {/* {data?.isArrived ? (
                <Chip
                  label="Arrived"
                  color="primary"
                  sx={{
                    height: "33px",
                    paddingX: "10px",
                  }}
                />
              ) : null} */}
            </div>
          </div>
        </Link>
        <div className={Style.iconWrapper}>
          {reservationFilter ? (
            <div>
              <div className={Style.iconBox}>
                <img src={Calender} className={Style.iconImg} alt="calender" />
                <p className={Style.iconText}>
                  {data?.checkinCount?.length
                    ? data?.checkinCount[0]?.checkinCount?.toString()
                        ?.length === 1
                      ? "0" + data?.checkinCount[0]?.checkinCount
                      : data?.checkinCount[0]?.checkinCount || 0
                    : 0}
                </p>
              </div>
              <div className={Style.iconBox}>
                <img src={Glass} className={Style.iconImg} alt="glass" />

                <p className={Style.iconText}>
                  {data?.reservationCount?.length
                    ? data?.reservationCount[0]?.reservationCount?.toString()
                        ?.length === 1
                      ? "0" + data?.reservationCount[0]?.reservationCount
                      : data?.reservationCount[0]?.reservationCount || 0
                    : 0}
                </p>
              </div>
            </div>
          ) : (
            ""
          )}
          {!filtered && (
            <div className={Style.iconBoxBtn} style={{ display: "contents" }}>
              {reservation && (
                <IconButton sx={{ p: 1 }}>
                  <Checkbox
                    checked={isArrived}
                    onChange={(e) => handleIsArrived(e, data)}
                    className={Style.checkbox}
                    sx={{ p: 0 }}
                  />
                </IconButton>
              )}

              <IconButton
                sx={{ p: 1 }}
                onClick={() => {
                  window?.open(`tel:${data?.contactNo}`);
                }}
              >
                <img src={Phone} alt="phone" />
              </IconButton>
              {dashboardActions && role === USER_TYPE.MANAGER && (
                <IconButton
                  sx={{ p: 1 }}
                  onClick={() => {
                    handleSendMessage(data?.contactNo);
                    dispatch(setPreviousScreen(location.pathname));
                  }}
                >
                  <img src={Message} alt="message" />
                </IconButton>
              )}
            </div>
          )}
        </div>
      </div>

      {data?.category?.length > 0 && component === "whosHere" ? (
        <div className={Style.initialWrapper}>
          {data?.category?.map((cat, innerIndex) => (
            <p className={Style.textField} key={innerIndex}>
              {cat.alphabets}
            </p>
          ))}
        </div>
      ) : null}
    </div>
  );
}

export default CustomerList;
